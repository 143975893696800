import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { addSubscriptionEndpoint as endpoint } from "../apiEndPoints";
import { IAddSubscription } from "../subscriptionType";


const AddSubscriptionAPI = async ({ header,users, planName,monthlyAmount,annualAmount,policyCount,createdBy }: IAddSubscription) => {
  const url = endpoint()
  const options: FetchOptions= {
    method: "POST",
    headers: header,
    body: JSON.stringify({
        planName,monthlyAmount,annualAmount,policyCount,createdBy,users
    }),
  }

  return fetchInterceptor(url, options)

};

export default AddSubscriptionAPI;
