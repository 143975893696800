import React, { useState } from "react";
import Header from "./components/Header/header";

import { SafeKaroUser } from "./context/constant";
import Footer from "./components/Footer/Footer";
import SuperAdminSideBar from "./components/Sidebar/SuperAdminSideBar";
interface SidebarSwitcherProps {
  userData: SafeKaroUser | undefined;
  content: React.ReactNode;
}

const SidebarSwitcher: React.FC<SidebarSwitcherProps> = ({
  userData,
  content,
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  if (!userData) return null;
  const SidebarComponent = SuperAdminSideBar;

  return (
    <div className="flex  bg-gray-100">
      <div
        className={`${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 z-20 absolute md:sticky left-0 md:top-0 transition-all duration-200`}
      >
        {SidebarComponent && (
          <SidebarComponent
            isSidebarOpen={isSidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
        )}
      </div>

      <div className="flex flex-col flex-1 overflow-y-auto">
        <Header isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} />

        {content}
        <Footer />
      </div>
    </div>
  );
};

export default SidebarSwitcher;
