import React from "react";
import { useLocation } from "react-router-dom";
import { ISubscription } from "../../../../api/Subscriptions/subscriptionType";
import WarperAddTable from "../../Wrapper/WarperAddTable";
import AddSubsFrom from "./AddSubsFrom";

const AddSubs = () => {
  const loc = useLocation();
  const data = loc.state as ISubscription;
  const title = data ? "Edit Subscription" : "Add Subscription";

 
  return (
    <WarperAddTable
      preLink="subscription"
      prePageName="Subscription"
      title={title}
    >
      {data ? <AddSubsFrom p={data} /> : <AddSubsFrom />}
    </WarperAddTable>
  );
};

export default AddSubs;
