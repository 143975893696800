
import { EditProps } from "../subscriptionType";
import EditSubscriptionApi from "./EditSubscriptionApi";

const EditSubscriptionService = async ({ header, editSubs, id }: EditProps): Promise<any> => {
    try {
        const res = await EditSubscriptionApi({
            header, editSubs, id
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditSubscriptionService;
