import { IAddMenu } from "../IMenuType";
import AddMenuAPI from "./AddMenuAPI";

const AddMenuService = async (payload: IAddMenu): Promise<any> => {
    try {
        const res = await AddMenuAPI(
            payload
        )
        return res;
    } catch (error) {
        throw error
    }

};

export default AddMenuService;