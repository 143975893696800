import React, { FC, useState } from "react";
import { IAdmin } from "../../IAdmin";
import { Form, Field } from "react-final-form";
import { setIn } from "final-form";
import * as yup from "yup";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import addTeamService from "../../../../api/Team/AddTeam/addTeamService";
import { useNavigate } from "react-router-dom";
import editTeamService from "../../../../api/Team/EditTeam/editTeamService";
import {
  ALLOWED_FILE_TYPES,
  DAY_FORMAT,
  header,
  MAX_FILE_SIZE,
  SafeKaroUser,
} from "../../../../context/constant";
import toast, { Toaster } from "react-hot-toast";
import dayjs from "dayjs";
import useSubscription from "../../../../Hooks/Subscription/useSubscription";

interface AddClientProps {
  propsData?: IAdmin;
}
const AddClientFrom: FC<AddClientProps> = ({ propsData }) => {
  const navigate = useNavigate();
  const storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  const UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const [plans] = useSubscription();
  const [selectedPlanId, setSelectedPlanId] = useState(propsData?.planId || "");

  const validationSchema = yup.object().shape({
    fullName: yup
      .string()
      .required("Full Name is required")
      .min(1, "Name must be at least 1 character")
      .max(100, "Name cannot exceed 100 characters"),
    originalPassword: yup
      .string()
      .required("Password is required")
      .min(1, "Password must be at least 1 character"),
    phoneNumber: yup
      .string()
      .required("Phone Number is required")
      .matches(/^\d+$/, "Phone Number must contain only digits")
      .min(10, "Phone Number must be exactly 10 digits")
      .max(10, "Phone Number must be exactly 10 digits"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    gender: yup
      .string()
      .required("gender is required")
      .min(1, "gender must be at least 1 character"),
    plan: yup.object().required("Plan is required"),
    image: yup.mixed().when([], {
      is: () => !propsData,
      then: yup
        .mixed()
        .required("File is required")
        .test("fileSize", "File size must be less than 2MB", (value) => {
          return value && value.size <= MAX_FILE_SIZE;
        })
        .test("fileType", "Only image file allowed", (value) => {
          return value && ALLOWED_FILE_TYPES.includes(value.type);
        }),
      otherwise: yup
        .mixed()
        .test("fileSize", "File size must be less than 2MB", (value) => {
          return !value || value.size <= MAX_FILE_SIZE;
        })
        .test("fileType", "Only image  are allowed", (value) => {
          return !value || ALLOWED_FILE_TYPES.includes(value.type);
        }),
    }),
  });

  const findPlanById = () => {
    return plans.find((ele) => ele._id === selectedPlanId);
  };
  const generateInitialData = () => {
    if (propsData) {
      const {
        fullName,
        email,
        originalPassword,
        gender,
        joiningDate,
        isActive,
        phoneNumber,
        branchName,
      } = propsData;
      return {
        fullName,
        email,
        phoneNumber,
        originalPassword,
        gender,
        joiningDate: dayjs(joiningDate),
        isActive,
        branchName,
        plan: findPlanById(),
      };
    } else {
      return {};
    }
  };
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      return errors;
    }
  };
  const validate = validateFormValues(validationSchema);

  const callAddClientAPI = async (team: any) => {
    try {
      await addTeamService({ header, team });
      navigate(-1);
    } catch (err: any) {
      const errObj = await err;
      toast.error(errObj.message);
    }
  };
  const callEditClientAPI = async (team: any, teamId: string) => {
    try {
      await editTeamService({ header, team, teamId });
      navigate(-1);
    } catch (err: any) {
      const errObj = await err;
      toast.error(errObj.message);
    }
  };
  const generateFormData = (obj: any) => {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (value === undefined || value === null) {
        return;
      }
      if (value instanceof File || value instanceof Blob) {
        formData.append(key, value);
      } else {
        formData.append(key, value.toString());
      }
    });

    return formData;
  };

  const handlePlanChange = (newValue: any) => {
    if (newValue?._id) {
      setSelectedPlanId(newValue._id);
    }
  };
  const onSubmit = (data: any) => {
    const {
      fullName,
      email,
      originalPassword,
      gender,
      joiningDate,
      phoneNumber,
      image,
      branchName,
      plan,
    } = data;
    const obj = {
      fullName,
      email,
      originalPassword,
      gender,
      joiningDate: dayjs(joiningDate).format(DAY_FORMAT),
      isActive: true,
      phoneNumber,
      image,
      role: "admin",
      branchName,
      headRmId: UserData.id,
      headRM: UserData.name,
      password: originalPassword,
      planId: selectedPlanId,
      planName: plan.planName || "",
    };

    if (propsData) {
      callEditClientAPI(generateFormData(obj), propsData._id!);
    } else {
      callAddClientAPI(generateFormData(obj));
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={generateInitialData()}
        validate={validate}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="branchName">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Branch Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="fullName">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Full Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="phoneNumber">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Phone Number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="tel"
                      inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="originalPassword">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Password"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="password"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="email">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Email"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="email"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              {}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="gender">
                  {({ input, meta }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                    >
                      <InputLabel>Select Gender</InputLabel>
                      <Select
                        {...input}
                        input={<OutlinedInput label="Select Gender" />}
                      >
                        {["male", "female", "other"].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {meta.touched && meta.error && (
                        <FormHelperText>{meta.error}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="joiningDate">
                  {({ input, meta }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Joining Date"
                        value={input.value || null}
                        onChange={(date) => input.onChange(date)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="plan">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="plan"
                          value={input.value !== undefined ? input.value : null}
                          options={plans}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option?.planName || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue ? newValue : null);
                            handlePlanChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Plan"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Field name="image">
                  {({ input, meta }) => (
                    <div>
                      <Grid item lg={12} xs={12}>
                        <input
                          type="file"
                          style={{
                            border: "1px solid #c4c4c4",
                            padding: "5px",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const file = event.target.files
                              ? event.target.files[0]
                              : null;
                            input.onChange(file);
                          }}
                          //   accept=".pdf"
                        />
                        {meta.touched && meta.error && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </Grid>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className=" w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                >
                  {propsData ? "Update Client" : "Add Client"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};
export default AddClientFrom;
