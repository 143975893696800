import React from "react";
import { useLocation } from "react-router-dom";
import WarperAddTable from "../../Wrapper/WarperAddTable";
import AddMenuFrom from "./AddMenuFrom";
import { IMenu } from "../../../../api/Menu/IMenuType";

const AddMenu = () => {
  const loc = useLocation();
  const data = loc.state as IMenu;
  const title = data ? "Edit Menu" : "Add Menu";

  return (
    <WarperAddTable preLink="menu" prePageName="Menu" title={title}>
      {data ? <AddMenuFrom p={data} /> : <AddMenuFrom />}
    </WarperAddTable>
  );
};

export default AddMenu;
