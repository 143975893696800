
export const addSubscriptionEndpoint = () => (`/api/subscriptions`);

export const editSubscriptionEndpoint = (subsId: string) =>
  (`/api/subscriptions/${subsId}`);

export const getSubscriptionEndpoint = () => (`/api/subscriptions`);

export const getSubscriptionByIdEndpoint = (subscriptionId: string) =>
  (`/api/subscriptions/${subscriptionId}`);

export const deleteSubscriptionEndpoint = (subscriptionId: string) =>
  (`/api/subscriptions/${subscriptionId}`);
