import { IAddSubscription } from "../subscriptionType";
import AddSubscriptionAPI from "./AddSubscriptionAPI";

const AddSubscriptionService = async ({ header, planName, monthlyAmount, annualAmount, users, policyCount, createdBy }: IAddSubscription): Promise<any> => {
    try {
        const res = await AddSubscriptionAPI({
            header, planName, monthlyAmount, annualAmount, policyCount, createdBy, users
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default AddSubscriptionService;