import React, { useEffect, useMemo, useState } from "react";

import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import dayjs from "dayjs";
import { DAYJS_DISPLAY_FORMAT } from "../../../context/constant";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { IconButton, Tooltip } from "@mui/material";
import { header } from "../../../context/constant";

import WarperViewTable from "../Wrapper/WarperViewTable";
import { IEditMenu, IMenu } from "../../../api/Menu/IMenuType";
import GetMenuService from "../../../api/Menu/GetMenu/GetMenuService";
import EditMenuService from "../../../api/Menu/EditMenu/EditMenuService";

const Menu = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [menuData, setMenuData] = useState<IMenu[]>();

  const findParentNameById = (id: string) => {
    const menuObj = menuData?.find((ele) => ele._id === id);
    const result = menuObj?.displayName;
    if (result) {
      return result;
    } else {
      return "";
    }
  };
 
  const columns = useMemo<MRT_ColumnDef<IMenu>[]>(
    () => [
      {
        accessorKey: "displayName",
        header: " Name",
        size: 200,
      },
      {
        accessorKey: "pageURL",
        header: "Page Url",
        size: 200,
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 200,
      },

      {
        accessorKey: "parentId",
        header: "Parent menu",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue() as string;
          return findParentNameById(value);
        },
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return dayjs(value as string | number | Date).format(
            DAYJS_DISPLAY_FORMAT
          );
        },
      },

      {
        header: "Status",
        accessorKey: "isActive",
        size: 50,
        Cell: ({ cell }) => {
          const value = cell.getValue<boolean>();
          return value ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          );
        },
      },
    ],
     // eslint-disable-next-line
    [menuData?.length]
  );
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await GetMenuService({ header });
      if (res.status === "success") {
        setMenuData(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickEditMenu = (row: IMenu) => {
    navigate("edit", { state: row });
  };

  const handleClickChangeStatus = async (row: IMenu) => {
    try {
      const editData: IEditMenu = {
        id: row._id,
        isActive: !row.isActive,
      };
      const res = await EditMenuService(editData);

      if (res.status === "success") {
        fetchData();
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <WarperViewTable title="Menu">
        <MaterialReactTable
          state={{ isLoading, pagination }}
          columns={columns}
          data={menuData || []}
          enableRowActions
          onPaginationChange={setPagination}
          autoResetPageIndex={false}
          paginateExpandedRows={false}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <Tooltip title={"Edit Menu"}>
                <IconButton
                  color="primary"
                  aria-label={"Edit Menu"}
                  component="span"
                  onClick={() => {
                    handleClickEditMenu(row.original as IMenu);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              <Tooltip title={"Change Status"}>
                <IconButton
                  color="primary"
                  aria-label={"Change Status"}
                  component="span"
                  onClick={() => handleClickChangeStatus(row.original as IMenu)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </div>
          )}
        />
      </WarperViewTable>
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};

export default Menu;
