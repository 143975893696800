import { header } from "../../../context/constant";
import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editMenuEndpoint as endpoint } from "../apiEndpoints";
import { IEditMenu } from "../IMenuType";


const EditMenuApi = async (payload: IEditMenu) => {
    const url = endpoint(payload.id)
    const options: FetchOptions = {
        method: "PUT",
        headers: header,
        body: JSON.stringify({
            ...payload,
        }),

    }

    return fetchInterceptor(url, options)

};

export default EditMenuApi;